import { DashboardGroupCursorConnection } from '@/types/graphql';
import { ClientCompany } from '@/types/navigation';
import { GetTransactionsQuery } from '@/types/graphql/transactions';

export type BaseCheckboxTypes = 'primary' | 'secondary';
export type IconPosition = 'left' | 'right';
export type FormLayoutType = 'default' | 'modal';
export type DeactivationType = 'user' | 'company';

export enum Step {
  One = 'step1',
  Two = 'step2',
  Error = 'error',
}

export type StepsType = {
  [key: string]: boolean;
};

export interface MultiselectOptions {
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
}

export interface SaMultiselectOptions extends MultiselectOptions {
  products: string[];
}

export interface ProductsListItemModel {
  name: string;
  abbr: string;
  id: string;
  icon: string;
  link: string;
  active: boolean;
}

export interface RouterList {
  [key: string]: {
    title: string;
    name: string;
    path: string;
    mainRoute: boolean;
  };
}

export interface GoBackRoute {
  name: string;
  path: string;
  baseName?: string;
}

export interface FilterOptions {
  value: string;
  label: string;
}

export interface actionButtonModel {
  actionButtonId: string;
  toActionLink?: string;
  actionButtonText: string;
  actionButtonIcon: string;
  actionButtonOutlined: boolean;
  actionButtonState?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  toggleButtonState: Function;
}
export interface GlobalStateModel {
  showGoToCalendlyButton: boolean;
  headerTitle: string;
  actionButtons: Array<actionButtonModel>;
  productSwitcherPopupOpened: boolean;
  showChat: boolean;
  isClientViewSelectionModalOpen: boolean;
}

export interface ToggleSwitchTextOptions {
  first: string;
  second: string;
}

export interface TableTabNames {
  tabValue: string;
  isActive: string;
}
export interface ServicesListItemModel {
  term: string;
  disabled: boolean;
}
export interface ServicesPerProductModel {
  [key: string]: {
    [key: string]: boolean;
  };
}

export interface UsersListPerProduct {
  [x: string]: MultiselectOptions[];
}

export interface UsersPerProductMultiselectView {
  [x: string]: boolean;
}

export interface ModulesListItem {
  id: string;
  name: string;
  slug?: string;
  dashboardGroups?: DashboardGroupCursorConnection;
}

export interface checkboxesType {
  abbreviation: string | null | undefined;
  id: string | undefined;
  companyId: string;
  selected: boolean | undefined;
}

export interface tableDataType {
  id: string;
  legalName: string | undefined;
  checkboxes: checkboxesType[] | undefined;
}
export interface chatMembersType {
  _id: string | undefined;
  username: string | undefined;
  avatar: string | undefined;
  clientCompany: string;
  roles: string | undefined;
}
export interface detailsType {
  room?: {
    roomId?: string | null | undefined;
    roomName?: string | null | undefined;
    clientCompanyId?: string | null | undefined;
    unreadCount?: string | number;
  };
  options?: {
    reset: boolean | undefined;
  };
}

export interface MessageFile {
  name: string;
  type: string;
  extension: string;
  url: string;
  localUrl?: string;
  preview?: string;
  size?: number;
  audio?: boolean;
  duration?: number;
  progress?: number;
  blob?: Blob;
}

export interface AvatarImage {
  src: string;
  type: string;
}
export interface FileData {
  file: {
    file: {
      type: string;
      url: string;
      extension: string;
    };
  };
}
export interface RoomProduct {
  clientCompany: ClientCompany | null;
  id: string;
  name: string;
  abbr: string;
  isProductManager: boolean | undefined;
  available: boolean;
}
export interface TransformedMessage {
  _id: string;
  senderId: string;
  content: string | undefined;
  username: string | undefined;
  sortingDate: string | undefined;
  avatar: string;
  date: string;
  timestamp: string | undefined;
  files: MessageFile[];
}
export interface MercureFile {
  name: string;
  type: string;
  extension: string;
  url: string;
}

export interface RepresentativePerson {
  id?: string | null;
  index?: number;
  type?: string | undefined;
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
  phoneNumber: string;
  indices?: number[];
  companyRepresentativeProducts?: string[] | undefined;
  selectedProductsIds?: string[];
}

export interface HashTab {
  title: string;
  hash: string;
}
export interface requestOptionsType {
  method: string;
  headers?: {
    Authorization: string;
    'Client-View-Company-Id'?: string;
    'Active-Product'?: string;
    'Client-View'?: string;
  };
}
export interface ProductsTypes {
  id: string;
  abbreviation: string;
  name: string;
  isProductManager: boolean;
}
export interface SustainabilityAdvisorProductType {
  id: string;
  abbr?: string;
  isProductManager: boolean;
}

export interface PhoneObject {
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  country: {
    name: string;
    iso2: string;
    dialCode: string;
  };
  countryCode: string;
  valid: boolean;
  formatted: string;
}

export type TransactionFromQuery = NonNullable<
  NonNullable<GetTransactionsQuery['transactions']>['collection']
>[0];

export type InfoMessageType = {
  id: string;
  enabled: boolean;
  title: string;
  buttonLink: string;
  buttonText: string;
  list: string[];
  roles: string[];
};
