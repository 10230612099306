export const PRODUCTS_ABBR_LIST = {
  go: 'GO',
  biomethaneHeating: 'BIOMETHANE_HEATING',
  biomethaneTransport: 'BIOMETHANE_TRANSPORT',
  euEts: 'EU_ETS',
  vcm: 'VCM',
  hbe: 'HBE',
  thg_q: 'THG_Q',
  rtfc: 'RTFC',
  biofuels: 'BIOFUELS',
  f_gas: 'F_GAS',
  refrigerant_gas: 'REFRIGERANT_GAS',
  i_rec: 'I_REC',
  ppa: 'PPA',
};
export const PRODUCTS_NAMES_LIST = {
  go: 'Guarantee of Origin',
  biomethaneHeating: 'Biomethane — Heating',
  biomethaneTransport: 'Biomethane — Transport',
  euEts: 'EU ETS',
  vcm: 'Voluntary Carboon Markets',
  hbe: 'Hernieuwbare Brandstofeenheden',
  thgq: 'Treibhausgas-Quota',
  rtfc: 'Renewable Transport Fuel Certificate',
  biofuels: 'Biofuels',
  f_gas: 'Fluorinated Greenhouse Gasses',
  refrigerant_gas: 'Refrigerant Gas',
  i_rec: 'I_REC',
  ppa: 'Power Purchase Agreements',
};
