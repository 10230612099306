<script lang="ts">
import { computed, defineComponent, watch, ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import {
  iconBarToggle,
  initMainNavItems,
  mainNavItems,
  cleanMainNav,
  groupedModulesList,
  updateModulesGroups,
  replaceProductSlug,
  isIconBarOpen,
  toggleMenu,
  isMobileMenuOpen,
  windowWidth,
  isMobileWindowWidth,
} from '@/utils/composable/navigation';
import { modulesSideNavItems } from '@/config/constants/navigation';
import { UserRole, ViewType } from '@/types/user';
import { useUserStore } from '@/store';
import NavButton from '@/components/navigation/NavButton.vue';
import ViewSwitcher from '@/components/navigation/ViewSwitcher.vue';
import { MAIN_ROUTES } from '@/config/constants/routes';
import { navItem } from '@/types/navigation';
import { getTranslationTerms } from '@/utils/composable/localeHelper';
import ClickButton from '@/components/base/ClickButton.vue';
import BaseIcon from '@/components/base/BaseIcon.vue';
import LogoNav from '@/components/navigation/LogoNav.vue';

export default defineComponent({
  name: 'IconNavBar',
  components: {
    LogoNav,
    BaseIcon,
    ClickButton,
    NavButton,
    ViewSwitcher,
  },
  setup() {
    const userState = useUserStore();
    const { currentRoute } = useRouter();
    const { getUserRole } = storeToRefs(userState);
    const reselectClient = ref(false);
    const selectedProduct = computed(() => userState.getSelectedProduct);
    const viewCompany = computed(() => userState.getViewCompany);
    const viewType = computed(() => userState.getViewType);
    const isClientRole = computed(
      (): boolean => getUserRole.value === UserRole.ROLE_COMPANY_REPRESENTATIVE,
    );
    const isBSuppRole = computed(
      (): boolean => getUserRole.value === UserRole.ROLE_BUSINESS_SUPPORT,
    );
    const getGroupsTerms = getTranslationTerms.bind(null, 'pages', 'dashboard.modulesGroups');
    const openedGroups = ref(['']);
    const activeGroupHover = ref('');
    const openSubMenu = (group: string) => {
      if (!isIconBarOpen.value) return;
      if (openedGroups.value.includes(group)) {
        openedGroups.value = openedGroups.value.filter((item) => item !== group);
      } else {
        openedGroups.value = [...openedGroups.value, group];
      }
    };

    onMounted(() => {
      if (
        (mainNavItems.value.length === 0 && typeof getUserRole.value === 'string') ||
        isBSuppRole.value
      ) {
        initMainNavItems(getUserRole.value ? getUserRole.value : UserRole.ROLE_BUSINESS_SUPPORT);
      }
    });
    const groupedModules = computed(() => {
      const groupsObject: Record<string, navItem[]> = {};
      Object.keys(groupedModulesList.value).forEach((group) => {
        if (groupedModulesList.value[group].length) {
          groupsObject[group] = replaceProductSlug(
            groupedModulesList.value[group].map(
              (moduleItem) =>
                modulesSideNavItems.filter(
                  (item) =>
                    item.moduleId === moduleItem.slug &&
                    (item.role ? item.role.includes(getUserRole.value as UserRole) : true),
                )[0],
            ),
            selectedProduct?.value?.abbr?.toLocaleLowerCase() as string,
          );
        }
      });
      return groupsObject;
    });
    updateModulesGroups();

    const showClientsList = computed(
      () =>
        selectedProduct?.value?.id &&
        isIconBarOpen.value &&
        viewType.value === ViewType.CLIENT &&
        (!viewCompany.value?.id || reselectClient.value),
    );

    watch(getUserRole, (newValue: UserRole | null) => {
      if (!newValue) {
        cleanMainNav();
        return;
      }
      initMainNavItems(newValue);
    });

    const handleClickOutside = () => {
      if (isMobileMenuOpen.value) {
        toggleMenu(false);
      }
    };

    const handleArrowClick = () => {
      if (windowWidth.value > 600) {
        iconBarToggle(!isIconBarOpen.value);
      } else {
        toggleMenu(false);
      }
    };

    const getIcon = (group: string) => {
      switch (group) {
        case 'TRADE_HISTORY':
          return 'ibm--bluepay';
        case 'INFORMATION_HUB':
          return 'idea';
        case 'COMMUNICATION':
          return 'communication--unified';
        case 'COMMODITY_MARKET':
          return 'currency--dollar';
        case 'ENERGY_EXCHANGE':
          return 'arrows--horizontal';
        default:
          return 'default-icon';
      }
    };

    return {
      mainNavItems,
      MAIN_ROUTES,
      viewType,
      viewCompany,
      selectedProduct,
      showClientsList,
      ViewType,
      isClientRole,
      groupedModules,
      getIcon,
      groupedModulesList,
      getGroupsTerms,
      currentRoute,
      iconBarToggle,
      openSubMenu,
      openedGroups,
      isIconBarOpen,
      handleClickOutside,
      handleArrowClick,
      isMobileWindowWidth,
      isMobileMenuOpen,
      toggleMenu,
      activeGroupHover,
    };
  },
});
</script>

<template>
  <div v-click-outside="handleClickOutside" class="navbar">
    <div class="navbar__cont">
      <div class="navbar__top">
        <LogoNav />
      </div>

      <nav class="navbar__icon-cont nice-scroll nice-scroll--white" data-unit-test="iconBar">
        <div
          v-if="(!isClientRole && selectedProduct?.id) || viewCompany"
          class="navbar__view-switcher"
        >
          <ViewSwitcher :collapsed="!isIconBarOpen" data-cy="iconNavBarViewSwitcher" />
        </div>
        <ul v-if="mainNavItems?.length > 0" class="navbar__list nice-scroll">
          <li
            v-for="(navItem, index) in mainNavItems"
            :key="`navItem${index}`"
            :class="[navItem.classes, 'navbar__list-item']"
          >
            <VTooltip
              theme="menu"
              placement="right-start"
              :triggers="!isIconBarOpen ? ['hover', 'touch', 'click'] : []"
            >
              <NavButton
                :component-path="navItem?.componentPath"
                :name="navItem?.name"
                :label="navItem?.label"
                :status="navItem?.status"
                :icon="navItem?.icon ? navItem.icon : ''"
                :type="navItem?.type"
                :path="navItem?.path ?? ''"
                @click="toggleMenu(false)"
              />

              <template #popper>
                <span class="menu-tooltip">{{ navItem?.label }}</span>
              </template>
            </VTooltip>
          </li>
          <!-- some weird sorting to get ENERGY_EXCHANGE at the top -->
          <template
            v-for="group in Object.keys(groupedModules).sort((a, b) =>
              a === 'ENERGY_EXCHANGE' ? -1 : b === 'ENERGY_EXCHANGE' ? 1 : 0,
            )"
            :key="group"
          >
            <li v-if="isClientRole && group === 'ENERGY_EXCHANGE'" class="navbar__list-item">
              <VTooltip
                theme="menu"
                placement="right-start"
                :triggers="!isIconBarOpen ? ['hover', 'touch', 'click'] : []"
              >
                <NavButton
                  :component-path="groupedModules[group]?.[0]?.componentPath"
                  :name="groupedModules[group]?.[0]?.name"
                  :label="groupedModules[group]?.[0]?.label"
                  :status="groupedModules[group]?.[0]?.status"
                  :icon="groupedModules[group]?.[0]?.icon ? groupedModules[group]?.[0].icon : ''"
                  :type="groupedModules[group]?.[0]?.type"
                  :path="groupedModules[group]?.[0]?.path ?? ''"
                  @click="toggleMenu(false)"
                />

                <template #popper>
                  <span class="menu-tooltip">{{ groupedModules[group]?.[0]?.label }}</span>
                </template>
              </VTooltip>
            </li>
            <li v-else class="navbar__list-item navbar__list-group">
              <VMenu
                :disabled="activeGroupHover !== group"
                :class="[
                  groupedModules[group]?.find((item) => item?.name === currentRoute.name)
                    ? 'active-child-route'
                    : '',
                  'group-nav-button-container',
                ]"
                placement="right-start"
                :triggers="
                  (!isIconBarOpen || !openedGroups.includes(group)) && !isMobileWindowWidth
                    ? ['hover', 'click']
                    : []
                "
                @mouseenter="activeGroupHover = group"
              >
                <NavButton
                  v-close-popper
                  :component-path="''"
                  :name="''"
                  :label="getGroupsTerms(group)"
                  :icon="getIcon(group)"
                  :type="'icon'"
                  group-item
                  :path="groupedModules[group]?.[0]?.path ?? ''"
                  status="disabled"
                  @click="openSubMenu(group)"
                />
                <template #popper>
                  <li
                    v-for="subItem in groupedModules[group]"
                    :key="subItem.moduleId"
                    class="navbar__sublist-item"
                  >
                    <NavButton
                      v-close-popper
                      class="nav-mb--hover-button"
                      :component-path="subItem?.componentPath"
                      :name="subItem?.name"
                      :label="subItem?.label"
                      :status="subItem?.status"
                      :icon="subItem?.icon ? subItem.icon : ''"
                      type="horizontal"
                      :path="subItem?.path ?? ''"
                      :show-text="true"
                      :icon-size="20"
                      @click="toggleMenu(false), openSubMenu(group)"
                    />
                  </li>
                </template>
              </VMenu>

              <ul
                v-if="openedGroups.includes(group) && isIconBarOpen"
                class="navbar__sublist-static"
              >
                <li
                  v-for="subItem in groupedModules[group]"
                  :key="subItem.moduleId"
                  class="navbar__sublist-item"
                >
                  <NavButton
                    :component-path="subItem?.componentPath"
                    :name="subItem?.name"
                    :label="subItem?.label"
                    :status="subItem?.status"
                    :icon="subItem?.icon ? subItem.icon : ''"
                    :type="subItem?.type"
                    :path="subItem?.path ?? ''"
                    :show-text="isIconBarOpen"
                    @click="toggleMenu(false)"
                  />
                </li>
              </ul>
            </li>
          </template>
        </ul>
        <ClickButton
          :data-cy="`toggle-menu-${isIconBarOpen ? 'closed' : 'open'}`"
          class="navbar__toggle-menu"
          @click="handleArrowClick"
        >
          <BaseIcon
            v-if="isIconBarOpen"
            :size="32"
            icon-name="chevron--left"
            data-cy="toggle-menu-closed"
          />
          <BaseIcon
            v-if="!isIconBarOpen"
            :size="32"
            icon-name="chevron--right"
            data-cy="toggle-menu-open"
          />
        </ClickButton>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navbar {
  z-index: 10;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &__toggle-menu {
    background: var(--color-primary-hover);
    padding: 0.3rem;
    align-self: center;

    &:hover {
      background: var(--color-primary);
    }

    :deep(.btn__text) {
      display: flex;
      align-items: center;
    }
  }

  &__top {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-4xs);
  }

  &__cont {
    position: relative;
    background: var(--color-primary);
    display: grid;
    padding: var(--space-xxs) 0;
    grid-template-rows: auto 1fr;
    min-height: 100%;
  }

  &__icon-cont {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: auto;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 var(--space-5xs);
    height: 100%;
    width: 100%;
    overflow: auto;

    @media screen and (min-width: $tablet-minus) {
      padding: 0 var(--space-3xs);
    }

    &.nice-scroll {
      --scrollbar-color: var(--color-menu-active);
      flex: 1;
      height: unset;

      .iconbar-expanded & {
        animation: hide-scroll 0.4s linear;

        @keyframes hide-scroll {
          0% {
            --scrollbar-color: var(--color-menu);
          }
          100% {
            --scrollbar-color: var(--color-menu);
          }
        }
      }
    }
  }

  &__list-item {
    padding: var(--space-5xs) 0;
    display: flex;
    justify-content: center;

    &.border--top {
      padding-top: var(--space-xxs);
      margin-top: var(--space-5xs);
    }
    &.border--bot {
      padding-bottom: var(--space-xxs);
      margin-bottom: var(--space-3xs);
    }
    &.border--top-bot {
      padding-top: var(--space-4xs);
      margin-top: var(--space-5xs);
      padding-bottom: var(--space-4xs);
      margin-bottom: var(--space-5xs);
    }

    :deep(.v-popper) {
      width: 100%;
    }

    &-notifictations {
      cursor: pointer;
    }
  }

  &__sublist-item {
    margin-bottom: remCalc(3);
  }

  &__sublist-static {
    padding-left: var(--space-sm);
  }

  &__sublist-wrapper {
    padding: 5px;
  }

  &__list-group {
    position: relative;
    display: block;

    &:has(.navbar__sublist-static) .group-nav-button-container {
      margin-bottom: 0.4rem;

      .router-link-exact-active {
        background: transparent;
        color: white;

        :deep(.nav-mb__text),
        :deep(.nav-mb__icon) {
          color: white;
        }
      }
    }

    &:has(a.router-link-exact-active) > a:first-child {
      background: var(--color-menu-active);
      color: var(--color-font);
      font-weight: 600;
    }

    .iconbar-expanded &:has(a.router-link-exact-active) > a:first-child {
      background: transparent;
      color: var(--color-shade-10);
      font-weight: 600;
    }
  }

  &__view-switcher {
    padding: var(--space-3xs) var(--space-3xs);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: remCalc(48);
  }
}

.border {
  &--top,
  &--bot,
  &--top-bot {
    position: relative;

    &::after,
    &::before {
      content: '';
      position: absolute;

      left: 50%;
      transform: translateX(-50%);

      width: calc(100% + #{remCalc(2)});
      height: remCalc(1);

      border-radius: var(--border-radius-small);
      background: var(--color-border-inverted);
    }
  }

  &--top {
    &::after {
      display: none;
    }
  }
  &--bot {
    &::before {
      display: none;
    }
  }

  &--top,
  &--top-bot {
    &::before {
      top: 0;
    }
  }

  &--bot,
  &--top-bot {
    &::after {
      bottom: 0;
    }
  }
}

.menu-tooltip {
  font-size: var(--text-s);
}
</style>
